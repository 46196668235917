<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="text-xs-left" style="padding: 0px !important;">
      <span>{{ schedule.id }}</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <v-list class="talk-toolbar__title" two dense flat style="padding-top: 0px;">
        <v-list-tile avatar flat class="talk-toolbar__title-text" style="padding: 0 !important;">
          <v-list-tile-avatar>
            <v-avatar size="45">
              <img v-if="schedule.contact.file && schedule.contact.file.url" :src="schedule.contact.file.url">
              <img v-else-if="schedule.contact.type == 1" src="static/icons/user_icon_1.png">
              <img v-else src="static/icons/monitor/user-groups-96.png">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <strong v-if="props.item" style="font-size: 18px;">
                {{schedule.contact.name}}
              </strong>
              <span v-else style="font-size: 18px;">Nome não definido</span>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <v-tooltip v-if="schedule.contact.checked == 2" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="green" small>
                    verified
                  </v-icon>
                </template>
                <span>
                  Contato validado
                </span>
              </v-tooltip>
              <v-tooltip v-if="schedule.contact.checked == 3" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="red" small>
                    do_not_disturb_on
                  </v-icon>
                </template>
                <span>
                  Contato inválido
                </span>
              </v-tooltip>
              <span style="font-size: 12px; padding-left: 5px;">
                <span v-if="schedule.contact.type == 1">
                  <span v-if="schedule.contact.number.toString().length == 12">
                    <strong>{{ schedule.contact.number | mask('+## (##) ####-####') }}</strong>
                  </span>
                  <span v-else-if="schedule.contact.number.toString().length == 13">
                    <strong>{{ schedule.contact.number | mask('+## (##) # ####-####') }}</strong>
                  </span>
                  <span v-else>
                    {{ schedule.contact.number }}
                  </span>
                </span>
                <span v-else>- - -</span>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <strong v-if="schedule.name" v-text="schedule.name"></strong>
      <span v-else>- - -</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <div v-if="schedule.status" style="text-align: center;">
        <v-icon :color="schedule.status.color">
          {{ schedule.status.icon }}
        </v-icon>
        <br>
        <strong v-text="schedule.status.name"></strong>
      </div>
      <strong v-else>{{ schedule.status_id }}</strong>
    </td>
    <td class="text-xs-center" style="padding: 0 10px !important; max-width: 240px; min-width: 240px;">
      <strong>
        <span v-if="schedule.repeat" style="color: orange;">
          <span>Repetir </span>
          <span v-if="schedule.repeat_type_id == 1"> todo mês</span>
          <span v-else-if="schedule.repeat_type_id == 2"> todo ano</span>
          <span v-else-if="schedule.repeat_type_id == 3"> toda semana</span>
        </span>
        <span v-else style="color: blue;">Envio único</span>
      </strong>
      <br>
      <span v-if="[4,5,7].includes(schedule.status_id)">
        Enviado em {{ schedule.date_time | moment('DD/MM/YY HH:mm') }}
      </span>
      <span v-else>
        <span v-if="schedule.repeat">Próximo envio em </span>
        <span v-else>Envio em </span>
        <span> {{ schedule.next_date_time | moment('DD/MM/YY HH:mm') }}</span>
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkSchedule',
    computed: {
      editData () {
        delete this.props.item.status_id
        return this.props
      },
      schedule () {
        return this.props.item
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>